<template>
    <div class="form">
        <div class="background background_blue background_broker background_broker_q">
            <div class="background__image background__image_broker"></div>
        </div>

        <div class="wrapper">
            <div class="content">
                <div class="content__container brokerQuestionnaire">
                    <p class="brokerQuestionnaire__title">{{ "b_anket" | localize }}</p>

                    <Questionnaire service="brokers" prefix="b" nextlink="/brokers-benefits" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Questionnaire from '@/components/common/Questionnaire.vue'

export default {
  name: 'Brokers_Questionnaire',
  components: { Questionnaire }
}
</script>